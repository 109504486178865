import React, { useState, useEffect } from 'react'
import { ClassNames } from '@emotion/react'
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'
import Compass from './compass'
import bp from './common/breakpoints'
import { PRIMARY_COLOR,BLACK_COLOR } from './common/color'
import { useLocation } from '@reach/router'

const MenuContainer = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 68%;
  transform: translate(-50%, -50%);
  margin-top: ${(props) => (props.ishomepage ? 'unset' : '-4rem')};
  @media (max-width: 1440px) {
    top: 54%;
    left: 72%;
  }

  ${bp[0]} {
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    margin-top: ${(props) => (props.ishomepage ? 'unset' : '-4rem')};
  }
`

const MenuList = styled.div`
  --circle-size: 360px;
  position: relative;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  transform: rotate(135deg) translate(0px) rotate(-225deg);

  ${bp[0]} {
    --circle-size: 420px;
  }

  ${bp[1]} {
    --circle-size: 440px;
  }

  ${bp[2]} {
    --circle-size: 440px;
  }

  ${bp[4]} {
    --circle-size: 550px;
    @media (max-width: 1440px) {
      ${bp[4]} {
        --circle-size: 500px;
      }
    }
  }
`
const MenuItem = styled(GatsbyLink)`
  display: grid;
  justify-items: center;
  align-content: center;
  row-gap: 0.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin: -35px; /**half of width */
  color: ${BLACK_COLOR};
  text-transform: uppercase;
  font-size: 0.75rem;
  font-family:'Source Code Pro';
  font-weight: 600;
  transform: ${(props) =>
    `rotate(${
      (360 / props.totalitems) * props.currentindex
    }deg) translate(calc(var(--circle-size) / 2)) rotate(${
      (360 / props.totalitems) * props.currentindex * -1 + 90
    }deg)`};
  opacity: 0.5;

  &:hover {
    color: ${BLACK_COLOR};
    opacity: 1;
  }

  &:active,
  &:focus {
    color: ${BLACK_COLOR};
  }

  &::before,
  &::after {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    width: 24px;
    height: 2px;
    background-color: ${PRIMARY_COLOR};
  }

  &::before {
    margin-right: -4px;
    right: 100%;
  }

  &::after {
    margin-left: -4px;
    left: 100%;
  }
`

const Menu = ({ ishomepage, isOpen, list }) => {
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <MenuContainer ishomepage={ishomepage} isOpen={isOpen}>
      <ClassNames>
        {({ css, cx }) => (
          <MenuList>
            {list.map((item, index) => (
              <MenuItem
                onClick={item.title === 'Home' ? null : handleScroll}
                key={item.title}
                totalitems={list.length}
                currentindex={index}
                to={item.to}
                activeClassName={cx(
                  css(`
                        opacity: 1 !important;
                        &::before, &::after { content: ''; }
                      `)
                )}
              >
                {item.icon}
                {item.title}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </ClassNames>
    </MenuContainer>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 80px);
  @media (max-width: 1024px) {
    display: none;
  }
`

const MainMenu = ({ ishomepage, isOpen, list }) => {
  const location = useLocation()
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const { pathname } = location
    const currentPathname = pathname.replace(/\/$/, '')
    const currentIndexFound = list.findIndex(
      (item) => item.to.replace(/\/$/, '') === currentPathname
    )
    setCurrentIndex(currentIndexFound)
  }, [location, list])

  return (
    <Container>
      <Compass ishomepage={ishomepage} currentIndex={currentIndex} />
      <Menu ishomepage={ishomepage} isOpen={isOpen} list={list} />
    </Container>
  )
}

export default MainMenu
