import React from 'react'
import styled from '@emotion/styled'
import { Text } from '../components/common/typography'
import bp from '../components/common/breakpoints'
import GitdoneSVG from '../images/icons/solutions/Gitdone.svg'
import CedarLogo from '../images/icons/solutions/cedar.svg'
import { FiArrowRightCircle } from "react-icons/fi";

const Wrapper = styled.div`
  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 90%;
  margin: 0 auto;
  z-index: 999;
  box-sizing: border-box;
  background-color: transparent;
  margin-bottom: 3rem;
  ${bp[3]} {
    width: 100%;
    max-width: 1320px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 4rem;
  ${bp[1]} {
    flex-direction: row;
  }
  ${bp[3]} {
    flex-direction: row;
  }
`
const Card = styled.div`
  display: grid;
  row-gap: 1rem;
  max-width: 100%;
  height: fit-content;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(256,256,256, 0.9);
  box-sizing: border-box;
  border-radius: 20px;  
  ${bp[1]} {
    max-width: 100%;
    min-width: 360px;
    min-height: fit-content;
    padding: 2rem;
  }
`
const CardDescription = styled(Text)`
  font-weight: 400;
  color: black;
  opacity: 0.5;
  text-align: center;
  margin-top: 16px;
  max-width: 400px;
  min-height: 180px;
`
const Link = styled.a`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #ea8d22;
    font-weight: 700;
  }
`
const Font = styled.p`
  font-size: 1.2rem;
  color: black;
  margin-right: 1rem;
  opacity: 0.6;
  font-weight: 300;
  &:hover {
    color: #ea8d22;
    opacity: 1;
  }
`
const HeadingWhite = styled.h1`
  margin: 0;
  color: white;
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 3rem;
  ${bp[2]} {
    font-size: 2.5rem;
  }
`

const ProductsLanding = () => {
  return (
    <Wrapper>
      <HeadingWhite>Products</HeadingWhite>
      <Container>
          <Card>
            <GitdoneSVG/>
            <CardDescription>
              Gitdone helps product owners and project or program managers have a clear view of the progress 
              of their development teams per product. Thanks to its native integration to Gitlab it is super 
              customizable and ready to show your development team's progress.<br/><br/>
              Gitdone produces valuable insight into your product roadmap by leveraging your dev teams collaboration data.
            </CardDescription>
            <Link href="https://www.gitdone.io/">
            <Font>Try it for free</Font>
            <FiArrowRightCircle style={{fontSize: "32px", color: "#ea8d22"}} />
          </Link>
          </Card>
          <Card>
            <CedarLogo/>
            <CardDescription>
              CEDAR, is an Infrastructure to Code solution that translates existing AWS configurations 
              ( and in a near future AZURE and GCP) into code in form of yaml file or configuration templates.<br/><br/>
              CEDAR will create the Templates for you and deploy to any account defined in your configuration.
            </CardDescription>
            <Link href="https://www.cedardeployer.io/">
            <Font>Try it for free</Font>
            <FiArrowRightCircle style={{fontSize: "32px", color: "#ea8d22"}} />
          </Link>
          </Card>
      </Container>
    </Wrapper>
  )
}


export default ProductsLanding