import React from 'react'
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'
import bp from './common/breakpoints'
import {
  BACKGROUND_COLOR_RGB,
  PRIMARY_COLOR_RGB,
  WHITE_COLOR,
} from './common/color'
import { ButtonNoStyle } from './common/button'
import CloseSVG from '../images/icons/cancel.svg'

const MenuContainer = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
`

const CloseButtonContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 80px;
  padding-right: 1rem;
  box-sizing: border-box;
`

const MenuList = styled.div`
  position: relative;
  height: 100%;
`

const MenuItem = styled(GatsbyLink)`
  display: grid;
  justify-items: start;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  grid-auto-flow: column;
  column-gap: 0.5rem;
  width: 100%;
  padding: 0.75rem 2rem;
  color: ${WHITE_COLOR};
  text-transform: uppercase;
  text-align: start;
  font-size: 0.75rem;
  opacity: 0.8;
  box-sizing: border-box;

  &:hover {
    color: ${WHITE_COLOR};
    opacity: 1;
  }

  &:active,
  &:focus {
    color: ${WHITE_COLOR};
  }
`

const Menu = ({ list }) => {
  return (
    <MenuContainer>
      <MenuList>
        {list.map((item, index) => (
          <MenuItem
            key={item.title}
            totalitems={list.length}
            currentindex={index}
            to={item.to}
            activeStyle={{ backgroundColor: PRIMARY_COLOR_RGB() }}
          >
            {item.icon}
            {item.title}
          </MenuItem>
        ))}
      </MenuList>
    </MenuContainer>
  )
}

const Container = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  top: 0;
  right: 0;
  position: fixed;
  width: 80%;
  height: 100%;
  background-color: ${BACKGROUND_COLOR_RGB(0.7)};
  z-index: 3000;

  ${bp[1]} {
    display: none;
  }
`

const MobileMenu = ({ isOpen, list, toggleMenu }) => {
  return (
    <Container isOpen={isOpen}>
      <CloseButtonContainer>
        <ButtonNoStyle onClick={() => toggleMenu(false)}>
          <CloseSVG />
        </ButtonNoStyle>
      </CloseButtonContainer>
      <Menu list={list} />
    </Container>
  )
}

export default MobileMenu
