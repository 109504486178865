import React, { useState, useEffect } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Header from '../header'
import MainMenu from '../main-menu'
import Services from '../servicesLanding.js'
import Hero from '../hero.js'
import { TABLET_PORTRAIT_DEVICE } from '../common/breakpoints'
import HomeSVG from '../../images/icons/nav/home.svg'
import ServicesSVG from '../../images/icons/nav/monitor.svg'
import AboutSVG from '../../images/icons/nav/cpu.svg'
import ResourcesSVG from '../../images/icons/nav/archive.svg'
import CarrersSVG from '../../images/icons/nav/briefcase.svg'
import PartnersSVG from '../../images/icons/nav/share-2.svg'
import TeamsSVG from '../../images/icons/nav/users.svg'
import SolutionsSVG from '../../images/icons/nav/layout.svg'
import MobileMenu from '../mobile-menu'
import ContactForm from '../contact-form'
import AlioVision from '../alioVision'
import ProductsLanding from '../productsLanding.js'

const SuperContainer = styled.div`
  width: 100%;
  height: 100%;
`
const Container = styled.div`
  min-width: 1400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 576px) {
    min-width: 576px;
  }
  @media (max-width: 768px) {
    min-width: 768px;
  }
  @media (max-width: 992px) {
    min-width: 992px;
  }
  @media (max-width: 1200px) {
    min-width: 1200px;
  }
`

const ContactContainer = styled.div`
  height: 100%;
  width: 100vh;
  z-index: 1;
  padding-top: ${(props) => (props.withContactPadding ? '0vh' : '0')};
  background-color: white;
`
const ProductsContainer = styled.div`
  height: 100vh;
  width: 100%;
  z-index: 999;
  background-color: white;
`
const ServicesContainer = styled.div`
  height: 100%;
  width: 100vh;
  z-index: 999;
  background-color: white;
`

const menuList = [
  {
    title: 'Home',
    hash: '/',
    to: '/',
    icon: <HomeSVG />,
  },
  {
    title: 'Services',
    to: '/services',
    icon: <ServicesSVG />,
  },
  {
    title: 'About',
    to: '/about',
    icon: <AboutSVG />,
  },
  {
    title: 'Resources',
    to: '/resources',
    icon: <ResourcesSVG />,
  },
  {
    title: 'Careers',
    to: '/careers-opportunity',
    icon: <CarrersSVG />,
  },
  {
    title: 'Partners',
    to: '/partners',
    icon: <PartnersSVG />,
  },
  {
    title: 'Teams',
    to: '/teams',
    icon: <TeamsSVG />,
  },
  {
    title: 'Solutions',
    to: '/solutions',
    icon: <SolutionsSVG />,
  },
]

const DefaultLayout = ({
  children,
  ishomepage = false,
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const matches = useMediaQuery(`(min-width: ${TABLET_PORTRAIT_DEVICE}px)`)

  useEffect(() => {
    if (matches) {
      setOpenMobileMenu(true)
    } else {
      setOpenMobileMenu(false)
    }
  }, [matches])

  return (
    <>
      <SuperContainer>
        <Container>
          <Header toggleMenu={setOpenMobileMenu} isMenuOpen={openMobileMenu} />
          <ProductsContainer>
            <Hero />
              <MainMenu
                ishomepage={ishomepage}
                isOpen={openMobileMenu}
                list={menuList}
              />
              <MobileMenu
                toggleMenu={setOpenMobileMenu}
                isOpen={openMobileMenu}
                list={menuList}
              />
          </ProductsContainer>
            {children}
          <ProductsContainer>
            <ProductsLanding />
          </ProductsContainer>
          <ServicesContainer>
            <Services />
          </ServicesContainer>
          <AlioVision />
          <ContactContainer>
            <ContactForm />
          </ContactContainer>
        </Container>
      </SuperContainer>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
